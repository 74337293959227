@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.modalContainer {
  :global(.MuiDialogTitle-root) {
    font-size: 1.75rem !important;
  }

  .loginForm {
    padding-top: 2.75rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 22.5rem;
    margin: 0 auto;

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      position: relative;

      > :nth-child(2) {
        margin-top: 1rem;
      }

      > div {
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        :global(.MuiFormLabel-root) {
          font-family: $jost;
          font-size: 1.25rem;
          color: $color-white;
          margin-top: -1rem;
          font-weight: 300;
          line-height: 1.875rem;
        }

        label {
          padding-left: 0;
        }

        @include bp(m) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    .forgotPassword {
      text-align: right;
      font-family: $jost;
      font-size: 0.875rem;
      cursor: pointer;
      color: $yellow;
      text-decoration: underline;

      &:hover {
        color: $primary-button-background-hover;
      }
    }
  }

  .createAccount {
    font-family: $jost;
    padding-top: 2rem;
    font-weight: 400;
    font-size: 0.875rem;
    cursor: default;
    display: flex;
    justify-content: center;

    span {
      padding-left: 0.3125rem;
      cursor: pointer;
      color: $yellow;
      text-decoration: underline;

      &:hover {
        color: $primary-button-background-hover;
      }
    }
  }
}
