$jost: 'Jost', sans-serif;
$dmSans: 'DM Sans', sans-serif;
$josefinSans: 'Josefin Sans', sans-serif;
$font-inter: 'Inter', sans-serif;
$raleway: 'Raleway', sans-serif;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$primary-light: #51ea1c;
$primary-main: #ff1838;
$primary-dark: #41c314;
$primary-extra-dark: #2e8b0e;
$primary-darker: $primary-extra-dark;
$primary-darkest: $primary-extra-dark;
$primary-light-background: rgba(66, 204, 18, 0.02);

$primary-input: #190a40;

$color-white: #ffffff;
$color-white-light: #f6f4ef;
$color-white-lighter: #f5f5f5;
$color-white-ghost: #f8fafb;

$primary-button-background: #fdc901;
$primary-button-background-hover: #ecd888;
$primary-button-shadow: rgba(28, 49, 68, 0.12) 0 4px 8px 0;

$color-grey: rgb(126, 136, 143);
$color-grey-light: rgb(218, 222, 227);
$color-grey-light-line: rgba(218, 222, 227, 0.1);
$color-grey-text: #d9d9d9;
$color-grey-rich-text: rgba(217, 217, 217, 0.2);
$color-grey-rich-text-hover: rgba(217, 217, 217, 0.3);
$color-grey-icon: #131a29;
$color-grey-border: #ededed;
$color-orange: #e9471d;
$color-orange-darker: #ee4216;
$color-charcoal-grey: #121212;
$color-dark-grey-second: #858585;
$color-light-grey-second: #dedede;
$color-light-grey-third: #bfbfbf;
$color-dark-grey-darker: #545454;
$color-light-grey: #dadada;

$box-shadow: 0rem 0rem 0.3125rem 0rem rgba(0, 0, 0, 0.05);

$color-light-blue: #eaf0f1;

$label: $color-white;
$label-focused: $primary-input;
$label-error: #d32f2f;

$input-border: rgba(145, 158, 171, 0.32);
$input-border-focused: $primary-input;
$input-border-error: $label-error;
$input-error-text: $label-error;

$background-color: #e8e9eb;
$dark-background: #333333;

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$bright-color: #ffffff;
$black-color: #000000;
$dark-background-color: #091017;

$red-color: #e13119;
$red-color-darker: #e04321;
$red-color-brighter: #efc5bd;
$red-color-2: #c40808;

$warning-color: #f19a3e;
$warning-color-brighter: #f6c48d;
$warning-color-darker: #98540b;

$sidebar-color: #313638;
$navigation-color: #313638;

$grey-border: rgba(255, 255, 255, 0.25);
$pink-background: #f34f91;
$pink-background-bright: #f6b7d1;
$accent-green: #45d476;

$shadow-dark: rgba(0, 0, 0, 0.25);
$shadow-darker: rgba(0, 0, 0, 0.37);

$background-color-dark: #3e3f4c;
$background-color-darker: #22252c;

$flash-background: rgba(255, 255, 255, 0.2);

$dark-blue-background: #091016;
$darker-blue-background: #0b1b2b;
$footer-background: #081015;
$yellow: #fdc901;
$light-yellow: #ffeca3;

$light-grey: rgba(255, 255, 255, 0.15);
$lighter-grey: #dedede;
$grey-text: #9196a5;
$light-grey-text: #cecece;
$dark-blue: #2a333e;
$grey-divider: rgba(255, 255, 255, 0.25);

$gradient-dark-background: linear-gradient(270deg, #19222b 0%, #141c24 100%);
$gradient-background: linear-gradient(360deg, #0b1b2b 6.9%, #080f15 98.42%);
$grey-gradient: linear-gradient(270deg, #232f3b 0%, #1f2934 100%);
$gradient-dark-background-lighter: linear-gradient(
  180deg,
  #091016 51.11%,
  #0b1a28 82.76%
);

$button-blue: #091119;
$input-dark: #182638;

$color-black-transparent: rgba(0, 0, 0, 0.05);
$color-black-less-transparent: rgba(0, 0, 0, 0.8);
$background-black-lower-opacity: rgba(0, 0, 0, 0.35);
$grey-border-table: #868686;

$label-green: #0bc807;
$label-red: #c80707;
$label-yellow: rgb(255, 193, 7);

$background-black-with-opacity: rgba(0, 0, 0, 0.65);

$grey-medium-color: #808080;
$grey-light-color: #e5e5e5;
$grey-lightest-color: #f2f2f2;

$modal-content-text-color: rgb(99, 115, 129);
$modal-border-color: rgba(255, 255, 255, 0.2);

$light-grayish-blue-color: #bec0c7;
$dark-gradient-background: linear-gradient(
  360deg,
  #0b1b2b 6.9%,
  #080f15 98.42%
);

$certificate-form-sidebar-panel-color: rgba(241, 241, 241, 255);
$dark-grey: #b8b8b8;

$alert-warning-color: rgb(122, 79, 1);
$alert-warning-background: rgb(255, 247, 205);
$alert-warning-icon: rgb(255, 193, 7);

$alert-info-color: rgb(4, 41, 122);
$alert-info-background: rgb(208, 242, 255);
$alert-info-icon: rgb(24, 144, 255);

$alert-success-color: rgb(8, 102, 13);
$alert-success-background: rgb(233, 252, 212);
$alert-success-icon: rgb(84, 214, 44);

$lighter-bright-color: #b7b7b7;
$color-grey-background-dark: #e8e8e8;
$color-grey-dark: #5e5e5e;
$grey-dark: #bebebe;
$light-blue-background-gradient: linear-gradient(
  180deg,
  #f2f7fc 50%,
  rgba(255, 255, 255, 0) 94.81%
);
$lighter-black-color: #1c1c1c;
$dark-grey-text: rgba(34, 51, 84, 0.7);

$color-grey-blue: #f1f3f4;
$background-black-lower-opacity: rgba(0, 0, 0, 0.35);
$color-light-grey-third: #a4a4a4;
$modal-close-button: #ffffff40;
$color-very-light-grey: #f8f8f8;
$background-light-grey: rgba(145, 158, 171, 0.12);

$table-background: rgba(34, 51, 84, 0.02);
$table-header-text: rgba(34, 51, 84, 0.7);
$table-border: rgba(34, 51, 84, 0.1);
$table-hover: rgb(99, 115, 129);

$navigation-shadow: rgba(255, 255, 255, 0.02);
$footer-separator: #2d3438;
$dark-shade-blue: rgba(88, 108, 157, 1);

$color-midnight-blue: #0a141d;
$dark-blue-hover: #3e4650;
$yellow-hover: #ecd888;

$teal-button-background: linear-gradient(180deg, #0dbea4 0%, #008972 100%);
$teal-button-background-hover: linear-gradient(
  180deg,
  #0ca58e 0%,
  #017763 100%
);
$teal-button-border: #3c9384;

$teal-dark-button-background: linear-gradient(
  151.57deg,
  #008972 17.56%,
  #08443b 112.11%
);
$teal-dark-button-background-hover: linear-gradient(
  151.57deg,
  #007965 17.56%,
  #073a32 112.11%
);
$teal-dark-button-border: #3a786e;

$secondary-red-background: linear-gradient(180deg, #ff0d64 0%, #da0451 100%);
$secondary-red-background-hover: linear-gradient(
  180deg,
  #e50b5b 0%,
  #c10449 100%
);
$secondary-red-border: #c53e6f;

$black-box-shadow: 0rem 0rem 1rem 0rem #0000005e inset,
  0rem 0.25rem 0.25rem 0rem #00000040;

$red-gradient-background: linear-gradient(180deg, #e12166 0%, #b00b46 100%);
$red-gradient-background-2: linear-gradient(180deg, #df2065 0%, #b00b47 100%);
$red-gradient-background-3: linear-gradient(180deg, #f1246e 0%, #b00845 100%);

$teal-color: #148170;
$color-teal-2: #008972;
$color-teal-light: #0dbea4;
$color-dark-teal: #082d27;

$grey-box-shadow: 0rem 0rem 1.5625rem 0rem #ffffff80;

$magenta-color: #df2065;

$progress-bar-red-background: #be0d0d;
$progress-bar-blue-background: #166adf;

$pastel-green-background: #e6f6ed;
$color-dark-red: #730101;
$color-dark-red-lighter: #c40808;

$joker-treasure-leaderboard-score-vertical-line-background: #00000040;

$orange-gradient-background: linear-gradient(180deg, #c8511a 0%, #a5190f 100%);
$timer-red: #f1246e;

$vip-button-color: rgba(253, 201, 1, 1);

$warzone-primary-button: linear-gradient(180deg, #a7ba56 0%, #4b5427 100%);
$warzone-primary-button-hover: linear-gradient(
  180deg,
  #6b7735 0%,
  #242814 100%
);
$warzone-primary-button-secondary-hover: #535d2b;
$warzone-disabled-button: linear-gradient(180deg, #d2232b 0%, #6c1216 100%);
$warzone-disabled-button-hover: linear-gradient(
  180deg,
  #82151a 0%,
  #2e0708 100%
);
$warzone-modal-button: linear-gradient(180deg, #a7ba56 0%, #4d5528 100%);
$warzone-secondary-button-color: #545e2c;
$warzone-secondary-button-border: #4e5729;
$warzone-secondary-button-box-shadow: 0 0 1.25rem 0 #85944580 inset;
$warzone-game-label: #605553;
$warzone-game-level: #f9f4ee;
$warzone-details-background: rgba(255, 255, 255, 0.8);
$warzone-timer: #808f43;
$warzone-menu-modal-background: rgba(255, 255, 255, 0.1);
