@import '../../styles/mixins';
@import '../../styles/variables';

.modalContainer {
  :global(.MuiDialogContent-root) {
    &::-webkit-scrollbar {
      background-color: $red-color-2;
      border-radius: 1.25rem;
      width: 0.5625rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $black-color;
      border-radius: 1.25rem;
    }
  }

  .backButtonContainer {
    display: flex;
    margin-top: 1.37rem;
    margin-left: 0.62rem;
    gap: 0.19rem;
    color: $primary-main;
    transition: 0.2s;
    font-family: $jost;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;

    &:hover {
      cursor: pointer;
      color: $primary-dark;
    }
  }

  .modalTitle {
    padding-top: 2.25rem !important;
    display: flex;
    justify-content: flex-start;
    font-family: $jost !important;
    font-size: 1.5rem;
    font-weight: 600 !important;
    color: $color-white;
    font-size: 1.5rem;
    cursor: default;

    img {
      margin: 0 auto;
    }

    @include bp(s) {
      font-size: 2rem;
    }

    @include bp(s) {
      padding-top: 2.56rem !important;
      justify-content: center;
      font-size: 2rem;
    }

    .image {
      max-width: 11.8125rem;
      align-self: center;
    }

    .isTitleCentered {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 2.5rem;
      width: 100%;
    }

    .isTitleLeftAligned {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }
  }

  .closeIcon {
    position: absolute;
    top: 0.87rem;
    right: 0.81rem;
    cursor: pointer;

    svg {
      display: flex;
      width: 1.8125rem;
      height: 1.8125rem;
    }

    @include bp(s) {
      top: 1rem;
      right: 1.06rem;
    }
  }
  &.isGameModal {
    :global(.MuiDialog-paperScrollPaper) {
      min-width: 25rem;

      @include bp(s) {
        min-width: 31.5rem;
        margin: 2rem;
      }
    }
  }

  :global(.MuiDialog-paperScrollPaper) {
    border-radius: 1rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid $modal-border-color;
    background-color: $input-dark;
    color: $color-white;
    background-image: none;
    margin: 0;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 4rem);
    max-width: 75rem;
    box-shadow: unset;

    @include bp(s) {
      min-width: 31.5rem;
      margin: 2rem;
    }

    h2 {
      margin: 0;
      font-weight: 700;
      line-height: 1.55556;
      font-family: $jost;
      flex: 0 0 auto;
      padding: 1.5rem 1.5rem 0;
      font-size: 1.0625rem;

      @include bp(s) {
        font-size: 1.125rem;
      }
    }

    :global(.MuiIconButton-root:hover) {
      background-color: transparent;
    }

    :global(.MuiDialogContent-root) {
      padding: 0 1.5rem 3.38rem;
      flex: 1 1 auto;
      overflow-y: auto;
      border-top: 0;
      border-bottom: 0;

      :global(.MuiDialogContentText-root) {
        margin: 0;
        line-height: 1.5;
        font-size: 0.875rem;
        font-family: $jost;
        font-weight: 400;
        color: $modal-content-text-color;
      }
    }

    :global(.MuiDialogActions-root) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 0 0 auto;
      padding: 1.5rem;
    }
  }

  :global(.MuiBackdrop-root) {
    background: $background-black-with-opacity;
  }

  &.isSmall {
    :global(.MuiDialog-paperScrollPaper) {
      max-width: 37.5rem;
    }
  }

  &.isFullWidth {
    :global(.MuiDialog-paperScrollPaper) {
      width: 100%;
    }

    :global(.MuiDialogContent-root) {
      padding: 0 !important;
      max-height: 40rem;
    }
  }

  &.isTransparent {
    :global(.MuiDialog-paperScrollPaper) {
      background-color: transparent;
      box-shadow: none;
    }
  }

  .explanationText {
    margin-top: 0.9375rem !important;
  }
}
