@import '../../styles/variables';
@import '../../styles/mixins';

.navigationContainer {
  display: flex;
  align-items: center;
  z-index: 99;
  width: 100%;
  background: $dark-blue-background;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0rem 0.25rem 0.25rem $navigation-shadow;
  height: 4.0625rem;
  position: relative;
  border-bottom: 0.0625rem solid $yellow;

  @include bp(xl) {
    height: 5.125rem;
    box-shadow: none;
  }

  .leftContainer {
    padding-left: 0.5rem;

    @include bp(s) {
      padding-left: 3rem;
    }

    @include bp(xl) {
      padding-left: 11.75rem;
    }

    &.loggedIn {
      @include bp(ml) {
        padding-left: 3rem;
      }

      @include bp(xxl) {
        padding-left: 11.75rem;
      }
    }

    img {
      width: 8.0625rem;

      @include bp(m) {
        width: 11.875rem;
      }
    }
  }

  .rightContainer {
    padding-right: 0.5rem;
    display: flex;
    flex-direction: row;
    gap: 3.25rem;
    align-items: center;
    font-family: $jost;

    @include bp(s) {
      padding-right: 2rem;
    }

    @include bp(xl) {
      padding-right: 11.875rem;
    }

    .navigationItems {
      display: flex;
      flex-direction: row;
      gap: 1.25rem;

      @include bp(l) {
        gap: 3.25rem;
      }

      div {
        font-weight: 500;
        font-size: 1.25rem;
        color: $color-white;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          text-shadow: 0.125rem 0.125rem 0.25rem $black-color;
          color: $yellow-hover;
        }
      }
    }

    .buttonsContainer {
      display: flex;
      gap: 1.0625rem;
      flex-direction: column;
      padding-top: 1.625rem;

      @include bp(xl) {
        flex-direction: row;
        gap: 0.875rem;
        padding-top: 0;
      }

      .balance {
        border-radius: 0.25rem;
        font-size: 1rem;
        font-weight: 300;
        background: $dark-blue;
        width: 18.5rem;
        min-width: 13.5rem;
        color: $color-white;

        &:hover {
          background-color: $dark-blue-hover;
        }

        @include bp(xl) {
          width: 13.5rem;
        }

        span {
          color: $yellow;
          font-size: 1rem;
          font-weight: 500;
          font-size: 1.25rem;
          text-transform: none;

          span {
            font-size: 1rem;
          }
        }
      }

      .vip {
        display: flex;
        gap: 0.5rem;
        font-size: 1.125rem;
        font-weight: 500;
        text-transform: none;
        background: $dark-blue;
        min-width: 11.8125rem;
        width: 18.5rem;
        color: $yellow;

        &:hover {
          background-color: $dark-blue-hover;
        }

        @include bp(xl) {
          width: 11.8125rem;
        }

        .vipIcon {
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 1.875rem;
            width: 1.875rem;

            svg {
              height: 100%;
              width: 100%;
            }
          }
        }
      }

      .loginButton,
      .profileButton {
        height: 3.25rem;
        background: $yellow;
        border-radius: 0.25rem;
        color: $black-color;
        display: flex;
        gap: 0.375rem;
        align-items: center;
        width: 18.5rem;
        min-width: 8.8125rem;

        &:hover {
          background-color: $yellow-hover;
        }

        @include bp(xl) {
          width: 8.8125rem;
        }

        div {
          width: 1.75rem;
          height: 1.75rem;
        }
      }
    }

    .mobileMenuLinks {
      padding: 2rem 0.75rem;
      top: 3.625rem;
      left: 0;
      right: 0;
      position: absolute;
      z-index: 99;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      background: $gradient-background;

      @include bp(xl) {
        gap: 1.25rem;
      }

      .link {
        color: $color-white;
        border-top: 0.0625rem solid $grey-divider;
        width: 100%;
        text-align: left;
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &.isLast {
          border-bottom: 0.0625rem solid $grey-divider;
        }
      }
    }
  }
}
