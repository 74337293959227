@import '../../../styles/mixins';
@import '../../../styles/variables';

.modalContainer {
  font-family: $jost;

  :global(.MuiInputLabel-root) {
    color: $color-white;
    font-size: 0.875rem;
    margin-top: -0.6rem !important;
    padding-left: 0;
  }

  :global(.MuiInputBase-root:hover) {
    border: 0.125rem solid $yellow;
    border-radius: 0.375rem;
  }

  :global(.MuiInputBase-root) {
    border: 0.125rem solid $color-white;
    border-radius: 0.375rem;
  }

  :global(.MuiOutlinedInput-input) {
    padding: 12px 14px;
  }

  :global(.MuiInputLabel-shrink) {
    color: $color-white !important;
    font-size: 0.875rem;
    margin-top: -0.6rem;
    padding-left: 0;
  }

  :global(.MuiDialogContent-root) {
    padding-bottom: 2.3rem !important;
  }

  :global(.MuiDialog-paperScrollPaper h2) {
    font-size: 2rem;

    @include bp(s) {
      font-size: 3rem !important;
    }
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $light-grey-text;
    gap: 1.25rem;
    font-size: 1rem;
    padding: 1.25rem 0.5rem 0;

    @include bp(xs) {
      font-size: 1.125rem;
    }

    @include bp(sm) {
      padding: 1.25rem 3rem 0;
    }

    .subtitle,
    .info {
      max-width: 27.5rem;
      text-align: center;
    }

    .subtitle {
      padding-bottom: 2rem;
    }

    .rate {
      font-weight: 700;
      color: $color-white;
    }
  }
}
