@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.cryptoCard {
  width: 100%;
  font-family: $jost;
  min-width: 18rem;

  @include bp(s) {
    min-width: 30rem;
  }

  .label {
    font-size: 0.875rem;
    padding-bottom: 0.125rem;
  }

  .addressContainer {
    display: flex;
    background-color: $grey-light-color;
    border-radius: 0.375rem;
    justify-content: space-between;
    align-items: center;
    padding: 0.5625rem;
    max-height: 3rem;

    .address {
      font-size: 0.9375rem;
      color: $black-color;
      user-select: all;
      cursor: pointer;
    }

    .copyIcon {
      cursor: pointer;
      color: $black-color;

      &:hover {
        color: $yellow;
      }

      &.copied {
        color: $primary-extra-dark;

        &:hover {
          color: $primary-extra-dark;
        }
      }

      div {
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;

        div {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    div {
      div {
        width: 1.875rem;
        height: 1.875rem;
      }
    }
  }
}
