@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.modalContainer {
  :global(.MuiDialogTitle-root) {
    font-size: 1.75rem !important;
  }

  .remindForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 22.5rem;
    margin: 0 auto;

    .info {
      padding-top: 1.625rem;
      text-align: center;
      font-size: 1.125rem;
      color: $light-grey-text;
      line-height: 1.625rem;
    }

    .form {
      padding-top: 2.75rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      position: relative;

      > div {
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        :global(.MuiFormLabel-root) {
          font-family: $jost;
          font-size: 1.25rem;
          color: $color-white;
          margin-top: -1rem;
          font-weight: 300;
          line-height: 1.875rem;
        }

        label {
          padding-left: 0;
        }

        @include bp(m) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
