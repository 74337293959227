@import 'styles/variables';
@import 'styles/mixins';

*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  height: 100%;
  overscroll-behavior-y: none;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0B1B2B;
  overscroll-behavior: contain;

  #root {
    height: 100%;

    --toastify-color-success: #51ea1c;
    --toastify-font-family: $jost;
    --toastify-icon-color-success: #51ea1c;
    --toastify-color-progress-success: #51ea1c;
  }
}

a {
  text-decoration: none;
  color: $primary-main;
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
