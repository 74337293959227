@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.modalContainer {
  .passwordResetForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 2.75rem;
    max-width: 22.5rem;
    margin: 0 auto;

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      position: relative;

      > :nth-child(2) {
        margin-top: 1rem;
      }

      > div {
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        :global(.MuiFormLabel-root) {
          font-family: $jost;
          font-size: 1.25rem;
          font-weight: 400;
          color: $color-white;
          margin-top: -1rem;
          padding: 0;
        }

        label {
          padding-left: 0;
        }

        @include bp(m) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
