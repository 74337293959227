@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.modalContainer {
  .invitation {
    text-align: center;
    font-family: $jost;
    font-size: 2rem;
    padding-top: 1rem;
    color: $yellow;
  }

  .registrationForm {
    padding-top: 2.75rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 22.5rem;
    margin: 0 auto;

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      position: relative;

      :global(.MuiCheckbox-root) {
        scale: 1.25;
        color: $color-white;
        font-family: $jost;
      }

      :global(.MuiFormControlLabel-label) {
        color: $color-white !important;
      }

      > div {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;

        :global(.MuiFormLabel-asterisk) {
          color: $color-white;
        }

        :global(.MuiFormLabel-root) {
          font-family: $jost;
          font-size: 1.25rem;
          font-weight: 400;
          color: $color-white;
          margin-top: -1rem;
        }

        label {
          padding-left: 0;
        }

        @include bp(m) {
          margin-left: 0;
          margin-right: 0;
        }
      }

      &:nth-child(6) {
        margin-top: 0;
      }

      &:nth-child(7) {
        margin-top: 0;
      }
    }

    .alreadyRegisteredContainer {
      font-size: 0.875rem;
      margin-top: 30px;
      font-family: $jost;
      text-align: center;

      .alreadyRegisteredLink {
        color: $yellow;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 0.3125rem;

        &:hover {
          color: $primary-button-background-hover;
        }
      }
    }
  }
}
