@import '../../styles/variables';
@import '../../styles/mixins';

.staticDateContainer {
  display: flex;

  :global(.MuiPickersCalendarHeader-label) {
    font-family: $jost;
    font-weight: 400;
    color: $primary-darkest;
    font-size: 1.375rem;
    text-transform: capitalize;
  }

  :global(.MuiPickerStaticWrapper-content) {
    background-color: transparent;
  }

  :global(.MuiPickersCalendarHeader-root) {
    margin-bottom: 2.5rem;
  }

  :global(.MuiSvgIcon-root) {
    color: $primary-darkest;
  }

  :global(.MuiDayPicker-weekDayLabel) {
    font-family: $jost;
    font-weight: 400;
    font-size: 1.25rem;
    color: $primary-darkest;
    width: 3.5rem;
  }

  :global(.MuiPickersDay-root) {
    font-family: $jost;
    font-weight: 400;
    font-size: 1.125rem;
    color: $primary-darkest;
    width: 3.5rem;
    border-radius: 0;
    background-color: transparent;
  }

  :global(.MuiPickersDay-root.Mui-selected) {
    background-color: $primary-main !important;
    color: $color-white;
  }

  :global(.PrivatePickersYear-yearButton.Mui-selected) {
    background-color: $primary-main !important;
    color: $color-white;
  }

  :global(.MuiCalendarOrClockPicker-root) {
    > div {
      width: 100%;
      max-height: none;
    }
  }

  :global(.MuiCalendarPicker-root) {
    width: 100%;
    max-height: none;
  }
}

.dateContainer {
  :global(.MuiPickersCalendarHeader-label) {
    font-family: $jost;
    color: $color-white !important;
    font-size: 1rem;
    font-weight: 500;
  }

  :global(.MuiPickersCalendarHeader-switchViewButton) {
    display: none;
  }

  :global(.MuiDateTimePickerToolbar-root) {
    display: none;
  }

  :global(.MuiDateTimePickerTabs-root) {
    display: none;
  }

  :global(.MuiDialogActions-root) {
    display: none;
  }

  .css-gztimk-MuiPaper-root-MuiPickersPopper-paper {
    background-color: transparent !important;
  }

  :global(.MuiCalendarPicker-root) {
    background-color: $input-dark;
    border: 0.0625rem solid $yellow;
    overflow: hidden;
    border-radius: 0.25rem;
  }

  :global(.MuiSvgIcon-root) {
    color: $yellow !important;
  }

  :global(.MuiDayPicker-weekDayLabel) {
    font-family: $jost;
    color: $grey-text;
    font-weight: 500;
    font-size: 0.875rem;
  }

  :global(.MuiPickersDay-root) {
    font-family: $jost;
    color: $color-white !important;
    border-radius: 0.5rem;
    background-color: $input-dark;
  }

  :global(.MuiPickersDay-root.Mui-selected) {
    background-color: $yellow !important;
    color: $black-color !important;
  }

  :global(.MuiPickersDay-root:hover) {
    color: $black-color;
    background-color: $yellow;
  }

  :global(.PrivatePickersMonth-root) {
    background-color: $lighter-grey;
    color: $dark-grey;
    border-radius: 0.5rem;
    margin: 0.35rem 0.31rem;
    height: 2rem;
    flex-basis: 4rem;
    font-family: $jost;
    font-size: 0.75rem;
  }

  :global(.PrivatePickersMonth-root:hover) {
    color: $black-color;
    background-color: $yellow;
  }

  :global(.PrivatePickersMonth-root.Mui-selected) {
    background-color: $yellow !important;
    color: $black-color;
  }

  :global(.PrivatePickersYear-root) {
    width: 3.875rem;
    flex-basis: 3.875rem;
    flex-grow: 1;
    margin-right: 0.6rem;
    margin-bottom: 0.6rem;
  }

  :global(.PrivatePickersYear-yearButton) {
    border-radius: 0.5rem;
    background-color: $lighter-grey;
    color: $dark-grey;
    font-family: $font-inter;
    font-size: 0.75rem;
    height: 2rem;
    margin: 0;
    width: 3rem;
  }

  :global(.PrivatePickersYear-root) {
    flex-basis: 2rem;
  }

  :global(.PrivatePickersYear-yearButton:hover) {
    color: $black-color;
    background-color: $yellow;
  }

  :global(.MuiYearPicker-root) {
    padding: 0 1.4375rem;

    &::-webkit-scrollbar {
      background-color: $light-grey;
      width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $dark-grey;
      border-radius: 0.375rem;
    }
  }

  :global(.PrivatePickersYear-yearButton.Mui-selected) {
    background-color: $yellow !important;
    color: $black-color;
  }

  :global(
      .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary
    ) {
    color: $yellow;
  }
}

.textField {
  width: 100%;

  :global(.MuiInputLabel-root) {
    color: $primary-input;
    padding-right: 0.563rem;
    padding-left: 0.7rem;
  }

  :global(.MuiAutocomplete-inputRoot) {
    padding-right: 0.563 !important;
    padding-left: 0.7rem !important;
  }

  :global(.MuiAutocomplete-root) {
    width: 100%;
  }

  :global(.MuiAutocomplete-input) {
    padding-left: 0.8rem !important;
  }

  :global(.MuiInputBase-root) {
    background-color: transparent;
  }

  :global(.MuiInputBase-root:hover) {
    border-radius: 0;
  }

  :global(.MuiInputBase-root) {
    border: 0.0625rem solid $color-light-grey;
    border-radius: 0.375rem;
    background-color: $color-white;
    font-family: $jost;

    &:hover,
    &:focus {
      border-radius: 0.375rem;
    }

    > fieldset {
      border: none;
    }

    textarea {
      padding-left: 0.7rem;
    }
  }

  &.currencyInput {
    p {
      margin: 0.063rem 0 0;
      line-height: 1.5;
      font-family: $jost;
      font-weight: 400;
      color: rgb(99, 115, 129);

      &:global(.Mui-error) {
        color: $input-error-text;
        margin: 0.5rem 0.875rem 0;
        font-size: 0.75rem;
        line-height: 1.66;
      }
    }
  }

  &.timeInput {
    :global(.MuiFormHelperText-sizeMedium) {
      position: absolute;
      top: 3.125rem;
    }
  }

  &.dateInput {
    width: 8.5rem;
    height: 2.5rem;

    :global(.MuiInputBase-formControl) {
      padding: 0.5rem 0.6rem;
    }

    :global(.MuiIconButton-root) {
      padding: 0;
      margin-right: 0;

      div div {
        display: flex;
      }
    }

    :global(.MuiInputAdornment-root) {
      margin-right: 0.2rem;
      margin-top: 0 !important;
      color: green;
    }

    :global(.MuiFilledInput-root:hover) {
      background-color: transparent !important;
    }

    input {
      padding: 0;
      margin: 0;
      color: $primary-main;
      font-family: $jost;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      @include bp(sm) {
        margin-top: 0;
        gap: 0.63rem;
      }

      &::placeholder {
        opacity: 1;
        text-align: left;
      }
    }
  }

  label {
    font-family: $jost;
    color: $label;
    font-size: 0.875rem;
    line-height: 1.4374em;
    font-weight: 400;
    padding: 0;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 1.5rem);
    position: absolute;
    margin-left: -0.8rem;

    &:global(.Mui-focused) {
      color: $label-focused;
      margin-top: -0.3rem;
    }

    &:global(.MuiFormLabel-filled) {
      margin-top: -0.3rem;
    }

    &:global(.MuiInputLabel-shrink) {
      margin-top: -0.3rem;
    }

    &:global(.Mui-error) {
      color: $label-error;
    }
  }

  > div {
    border-radius: 0;
    font-size: 0.875rem;
    font-family: $jost;
    font-weight: 400;
    line-height: 1.4375;

    input {
      font-family: $jost;
    }

    fieldset {
      border-color: $input-border;

      legend {
        width: auto;
      }
    }

    &:global(.Mui-focused) {
      fieldset {
        border-color: $input-border-focused !important;
      }
    }

    &:global(.Mui-error) {
      fieldset {
        border-color: $input-border-error !important;
      }
    }
  }

  p {
    font-family: $jost;
    margin: 0.5rem 0.875rem 0;

    &:global(.Mui-error) {
      color: $input-error-text;
    }
  }
}

.switcherContainer {
  margin-top: 0.625rem;

  :global(.MuiSwitch-switchBase) {
    color: $red-color;
  }

  :global(.Mui-checked) {
    color: $primary-main !important;
  }

  :global(.Mui-checked + .MuiSwitch-track) {
    background-color: $primary-button-background !important;
  }

  :global(.MuiSwitch-track) {
    background-color: $red-color;
  }

  :global(.MuiFormControlLabel-label) {
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: $jost;
    font-weight: 400;
  }
}

.checkboxContainer {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;

  :global(.MuiSvgIcon-root) {
    width: 1rem;
    height: 1rem;
  }

  :global(.MuiFormControlLabel-label) {
    font-family: $jost;
    font-weight: 300;
    color: $black-color;
    font-size: 1rem;
  }

  :global(.Mui-checked) {
    color: $yellow !important;
  }
}

.editorContainer {
  &.error {
    div:first-of-type {
      border-color: $red-color;
    }
  }

  .editorError {
    font-family: $jost;
    font-weight: 400;
    font-size: 0.75rem;
    color: $red-color;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 0.5rem 0.875rem 0;
  }
}

.checkBoxError {
  color: $label-error;
  font-family: $jost;
  font-weight: 400;
  font-size: 0.75rem;
  text-align: left;
  margin-top: -1.5rem !important;
  margin-left: 0.875rem;
  letter-spacing: 0.03333em;
}

.textFieldLoader {
  width: auto;
}

.optionsWithImage {
  display: flex;
  align-items: center;
}

.optionLabel {
  width: 5rem;
}
