@import '../../styles/variables';
@import '../../styles/mixins';

.buttonContainer {
  width: 18rem;
  height: 5.438rem;
  border: none;
  box-shadow: none;
  background-repeat: no-repeat;
  cursor: pointer;
  font-family: $jost;
  font-weight: 400;
  font-size: 1.875rem;
  color: $color-white;
  transform: scale(0.75);
  transition: 0.2s;

  &.greyButton {
    background: url(../../assets/grey_button.png);
  }

  &.greenButton {
    background: url(../../assets/green_button.png);

    &:hover {
      background: url(../../assets/green_button_hover.png);
    }
  }

  &.redButton {
    background: url(../../assets/button.svg);

    &:hover {
      background: url(../../assets/red_button_hover.png);
    }
  }

  &.jokerTreasureGameButton {
    border-radius: 6.25rem;
    box-shadow: $black-box-shadow;
    font-family: $raleway;
    font-weight: 600;
    height: 5rem;

    &.tealButton {
      background: $teal-button-background;
      border: 0.0625rem solid $teal-button-border;

      &:hover {
        background: $teal-button-background-hover;
      }
    }

    &.tealDarkButton {
      background: $teal-dark-button-background;
      border: 0.0625rem solid $teal-dark-button-border;

      &:hover {
        background: $teal-dark-button-background-hover;
      }
    }

    &.secondaryRedButton {
      background: $secondary-red-background;
      border: 0.0625rem solid $secondary-red-border;

      &:hover {
        background: $secondary-red-background-hover;
      }
    }
  }

  @include bp(l) {
    transform: scale(1);
  }
}

.button {
  height: 2.25rem;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:global(.Mui-disabled) {
    background-color: inherit;
  }

  &:global(.MuiLoadingButton-loading) {
    background-color: $yellow !important;
  }

  &:global(.MuiButton-containedPrimary) {
    background-color: $primary-button-background;
    box-shadow: none;
    border-radius: 0.25rem;
    padding: 0.688rem 1rem;
    font-family: $jost;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    height: 3.25rem;
    width: 100%;
    color: $black-color;

    &:hover {
      box-shadow: none;
      background-color: $primary-button-background-hover;
    }
  }

  &:global(.MuiButton-containedSecondary) {
    background-color: $button-blue;
    box-shadow: none;
    border-radius: 0.25rem;
    padding: 0.688rem 1rem;
    font-family: $jost;
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;
    height: 3.25rem;
    color: $yellow;
    width: 100%;

    &:hover {
      box-shadow: none;
      background-color: $dark-blue;
    }
  }

  &:global(.MuiButton-outlinedPrimary) {
    background-color: unset;
    border: 0.063rem solid $primary-button-background;
    color: $primary-button-background;
    box-shadow: none;
    border-radius: 1.5rem;
    padding: 0.688rem 2.5rem;
    font-family: $jost;
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;
    height: 3rem;
    width: 100%;

    &:hover {
      box-shadow: none;
      background-color: $primary-button-background-hover;
    }
  }

  &:global(.MuiButton-outlinedSecondary) {
    background-color: unset;
    color: $yellow;
    box-shadow: none;
    border-radius: 0.25rem;
    padding: 0.688rem 2.5rem;
    font-family: $jost;
    text-transform: capitalize;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;
    height: 3rem;
    width: 100%;

    &:hover {
      box-shadow: none;
      background-color: $dark-blue;
    }
  }

  &:global(.MuiButton-textPrimary) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 700;
    line-height: 1.71429;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-family: $jost;
    min-width: 4rem;
    padding: 0.375rem 0.5rem;
    border-radius: 0.5rem;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: $primary-button-background;

    &:hover {
      box-shadow: none;
    }
  }

  &.saveButton {
    height: auto;
    padding: 0.375rem 3rem;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.textButton {
    opacity: 0.8;
  }

  &.isActive {
    opacity: 1;
  }

  &.onlyIcon {
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 2.5rem;

    :global(.MuiButton-startIcon) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .loadingIcon {
    margin-right: 0.25rem;
  }
}
