@import '../../styles/mixins';
@import '../../styles/variables';

.alert {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: none;
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: $jost;
  font-weight: 400;
  border-radius: 0.5rem;
  display: flex;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0.5rem;

  &.danger {
    color: $error-text;
    background-color: $error-background;

    .icon {
      color: $error-icon;
    }
  }

  &.warning {
    color: $alert-warning-color;
    background-color: $alert-warning-background;

    .icon {
      color: $alert-warning-icon;
    }
  }

  &.info {
    color: $alert-info-color;
    background-color: $alert-info-background;

    .icon {
      color: $alert-info-icon;
    }
  }

  &.success {
    color: $alert-success-color;
    background-color: $alert-success-background;

    .icon {
      color: $alert-success-icon;
    }
  }

  .icon {
    margin-right: 0.75rem;
    padding: 0.438rem 0;
    display: flex;
    font-size: 1.375rem;
    opacity: 0.9;
  }

  .message {
    padding: 0.5rem 0;
  }
}
