@import '../../styles/variables';
@import '../../styles/mixins';

.modalContainer {
  font-family: $jost;
  text-align: center;
  max-width: 22.375rem;
  display: flex;
  flex-direction: column;
  gap: 1.625rem;
  margin: 0 auto;

  .title {
    font-weight: 600;
    font-size: 1.625rem;
  }

  .description {
    font-size: 1.125rem;
    color: $light-grey-text;
  }
}
