@import '../../styles/variables';
@import '../../styles/mixins';

.fileFieldContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.875rem;

  .fileField {
    background-color: $color-white-lighter;
    transition: padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 0.5rem;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    text-align: left;
    overflow: hidden;
    display: flex;
    outline: none;
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 3.9375rem;
    border: 0.0625rem solid $yellow;

    @include bp(s) {
      flex-direction: row;
    }

    input {
      display: none;
    }

    .fileContainer {
      width: 100%;
      height: 8.125rem;

      @include bp(s) {
        width: 10rem;
      }

      .fileSelectedContainer {
        height: 100%;

        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }

        .imageContainer {
          img {
            width: 7.5rem;
            height: 7.5rem;
            object-fit: cover;
            border-radius: 0.5rem;
          }
        }
      }
    }
  }

  .changePicture {
    button {
      width: 8.875rem;
      font-size: 1rem;
      font-weight: 500;
      padding-left: 0;
      padding-right: 0;
      border: 1px solid $yellow;
    }
  }
}

.fileError {
  margin-top: 1rem;
  font-family: $jost;
  font-weight: 400;
  font-size: 1.125rem;
  color: $label-error;
  flex-basis: 100%;
  order: 3;
}
