@import '../../styles/variables';
@import '../../styles/mixins';

.footer {
  background: $footer-background;

  .separatorLine {
    width: 100%;
    border: none;
    margin: 0;
    border-bottom: 0.063rem solid $footer-separator;
  }

  .footerContainer {
    width: 100%;
    height: 27rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: $jost;

    @include bp(ml) {
      height: 22.6875rem;
    }

    .content {
      padding: 2.4375rem 0.5rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      color: $color-white;

      @include bp(sm) {
        padding: 2.4375rem 2rem;
      }

      @include bp(ml) {
        padding: 4.5625rem 11.75rem 2.3125rem;
      }

      img {
        width: 8.0625rem;
        height: 2rem;
        cursor: pointer;

        @include bp(sm) {
          width: 11.875rem;
          height: 3.25rem;
        }
      }

      .contactContainer {
        align-self: flex-start;
        margin-top: 2.125rem;

        @include bp(ml) {
          align-self: flex-end;
          margin-top: 0;
        }

        .mailTo {
          width: 13.5rem;
          height: 3.25rem;
          display: flex;
          gap: 0.375rem;
          align-items: center;
          text-transform: none;
          background-color: $yellow;
          color: $black-color;
          padding: 0.688rem 1rem;
          border-radius: 0.25rem;
          font-weight: 400;

          &:hover {
            background-color: $light-yellow;
          }

          .mailIcon {
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 1.5rem;
              height: 1.5rem;
            }

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .footerLinks {
        padding-top: 1.5rem;
        font-size: 1.125rem;
        font-weight: 500;
        display: flex;
        gap: 1rem;
        flex-direction: column;

        .link {
          color: $color-white;
          cursor: pointer;

          &:hover {
            color: $yellow-hover;
          }
        }

        @include bp(ml) {
          flex-direction: row;
          gap: 5.375rem;
          padding-top: 3.5rem;
        }
      }

      .separationLine {
        width: 100%;
        border-bottom: 0.0625rem solid $light-grey;
        margin-top: 1.5rem;
        margin-bottom: 1.8125rem;

        @include bp(sm) {
          margin-top: 3rem;
        }
      }

      .allRightsReserved {
        color: $grey-text;
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 2.3125rem;
      }
    }
  }
}
